<template>
  <b-modal
    id="add-new-membership-category-sidebar"
    :visible="isAddNewmembershipSidebarActive"
    bg-variant="white"
    title="Add new membership lots parameter"
    size="xl"
    @hidden="closeSidebar"
    hide-footer
    @change="val => $emit('update:is-add-new-membership-category-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm">
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Type membership lots parameter name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="description"
            rules="required">
            <b-form-group label="Description" label-for="description">
              <b-form-input
                id="description"
                v-model="stateData.description"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Type membership lots parameter description" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="cant"
            rules="required|numeric">
            <b-form-group label="Cant" label-for="cant">
              <b-form-input
                id="cant"
                v-model="stateData.cant"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Type membership lots parameter cant" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
              <span v-if="!loading">{{ $t('Add') }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button type="button" variant="outline-danger" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import formValidation from '@/core/utils/forms/form-validation'
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SpinnerLoading from '@/components/SpinnerLoading'

import { required } from '@validations'
import { ref, watch } from '@vue/composition-api'

import axiosCT from '@/core/services/api/admin/consults/memberships'
import axiosAC from '@/core/services/api/admin/consults/membershipsLots'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isAddNewmembershipSidebarActive',
    event: 'update:is-add-new-membership-category-sidebar-active',
  },
  props: {
    memberships_parameters_id: {
      type: Number,
      required: true,
    },
    isAddNewmembershipSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      normalizerFacilities(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: [],
        }
      },
    }
  },
  setup(props, { emit }) {
    const facilities = ref([])
    const parameters = ref([{ type: null, count: null, activated_at: null }])
    const labos = ref([])
    const cat_lab = ref([])
    const options = ref([])
    const procedures = ref([])
    const sonog = ref([])
    const tests = ref([])
    const treats = ref([])
    const months = ref([])
    const { memberships_parameters_id } = props
    const facilityOptions = JSON.parse(atob(localStorage.getItem('setFacilityOptions')))
    const optionsStatus = [
      { name: 'Active', id: true },
      { name: 'Inactive', id: false },
    ]
    const stateDataTem = {
      name: null,
      description: null,
      cant: null,
    }

    const searchmemberships = ({ action, searchQuery, callback, parentNode }) => {
      if (action === 'ASYNC_SEARCH') {
        // const datos = {
        //     name: searchQuery,
        // };
        axiosCT.typeParameterSearch().then(({ data: { data } }) => {
          data = data.map(res => {
            if (res.identifier == 'consultations') {
              return {
                id: res.id,
                label: res.name,
                identifier: res.identifier,
              }
            }
            return {
              id: res.id,
              label: res.name,
              identifier: res.identifier,
              children: null,
            }
          })
          options.value = data
          callback(null, data)
        })
      }
    }

    const getMptParametrizables = (page = 1) => {
      axiosCT
        .mptParametrizables(page)
        .then(
          ({
            category_lab,
            procedures: { data: procedure_data },
            sonograms,
            tests: { data: test_data },
            treatments,
          }) => {
            labos.value = category_lab
            const newCatLab = category_lab.filter(labo => {
              if (labo.price) return labo
            })
            cat_lab.value = newCatLab
            procedures.value = procedure_data
            sonog.value = sonograms
            tests.value = test_data
            treats.value = treatments
          }
        )
    }

    getMptParametrizables()
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)))

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem))
    }
    const closeSidebar = () => {
      emit('createmembership', { status: false })
    }
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)
    checkMonths()

    watch(
      () => stateData.value,
      newVal => {
        if (newVal.validity != null || newVal.validity != undefined) {
          checkMonths()
        }
      },
      { deep: true }
    )
    function checkMonths() {
      if (stateData.value.validity > 12) return
      const newMonths = []
      for (let i = 1; i <= stateData.value.validity; i++) {
        newMonths.push({ id: `${i}`, label: `${i}` })
      }
      months.value = newMonths
    }

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      const datos = {
        ...stateData.value,
        memberships_parameters_id: memberships_parameters_id,
      }
      axiosAC
        .membershipParameterLotsCreate(datos)
        .then(({ registro }) => {
          loading.value = false
          resetuserData()
          emit('createmembership', { status: true, registro })
        })
        .catch(() => {
          loading.value = false
          emit('createmembership', { status: false })
        })
    }
    return {
      loading,
      stateData,
      optionsStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      searchmemberships,
      closeSidebar,
      facilities,
      months,
      facilityOptions,
      parameters,
      getMptParametrizables,
      options,
    }
  },
}
</script>

<style></style>
