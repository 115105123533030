<template>
  <b-modal
    id="add-new-diagnostic-image-category-sidebar"
    :visible="isEditReviewSidebarActive"
    size="xl"
    @hidden="resetForm"
    title="Edit Membership parameter lots"
    hide-footer
    @change="val => $emit('update:isEditReviewSidebarActive', val)">
    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm">
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Type membership lots parameter name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="description"
            rules="required">
            <b-form-group label="Description" label-for="description">
              <b-form-input
                id="description"
                v-model="stateData.description"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Type membership lots parameter description" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="cant"
            rules="required|numeric|max_value:99">
            <b-form-group label="Cant" label-for="cant">
              <b-form-input
                id="cant"
                v-model="stateData.cant"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Type membership lots parameter cant"
                :disabled="true" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
              <span v-if="!loading">Edit</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button type="button" variant="outline-danger" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import formValidation from '@/core/utils/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SpinnerLoading from '@/components/SpinnerLoading'

import { required } from '@validations'
import { ref, watch } from '@vue/composition-api'

import axiosCT from '@/core/services/api/admin/consults/membershipsLots'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isEditReviewSidebarActive',
    event: 'update:is-edit-system-review-sidebar-active',
  },
  props: {
    isEditReviewSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
    }
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { name: 'Active', id: 1 },
      { name: 'Inactive', id: 0 },
    ]

    const labos = ref([])
    const cat_lab = ref([])
    const options = ref([])
    const procedures = ref([])
    const sonog = ref([])
    const tests = ref([])
    const treats = ref([])

    let months = ref([])

    const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit.value)))

    const resetuserData = () => {
      emit('editReviewSuccess', false)
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value))
    }

    const loading = ref(false)

    checkMonths()

    watch(
      () => stateData.value,
      newVal => {
        if (newVal.validity != null || newVal.validity != undefined) {
          checkMonths()
        }
      },
      { deep: true }
    )
    function checkMonths() {
      if (stateData.value.validity > 12) return
      const newMonths = []
      for (let i = 1; i <= stateData.value.validity; i++) {
        newMonths.push({ id: `${i}`, label: `${i}` })
      }
      months.value = newMonths
    }

    const onSubmit = () => {
      loading.value = true
      const id = stateData.value.id
      const datos = {
        name: stateData.value.name,
        description: stateData.value.description,
      }
      axiosCT
        .membershipParameterLotsUpdate(id, datos)
        .then(() => {
          loading.value = false
          resetuserData()
          emit('editmembershipSuccess', true)
        })
        .catch(() => {
          emit('editmembershipSuccess', false)
          loading.value = false
        })
    }
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)
    return {
      stateData,
      loading,
      optionsStatus,
      options,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      months,
    }
  },
}
</script>

<style></style>
